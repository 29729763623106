import { Fragment, useRef, useState } from 'react';
import { Dialog, Transition } from '@headlessui/react';

interface IProps {
  children?: JSX.Element;
  open: boolean;

  onConfirm: () => void;
  onCloseModal: () => void;
}

const AlertcheckoutModal = (props: IProps) => {
  const { open, onConfirm, onCloseModal } = props;

  let [isOpen, setIsOpen] = useState(true);

  return (
    <Transition appear show={open} as={Fragment}>
      <Dialog as='div' className='relative z-10' onClose={console.log}>
        <Transition.Child
          as={Fragment}
          enter='ease-out duration-300'
          enterFrom='opacity-0'
          enterTo='opacity-100'
          leave='ease-in duration-200'
          leaveFrom='opacity-100'
          leaveTo='opacity-0'
        >
          <div className='fixed inset-0 bg-black bg-opacity-25' />
        </Transition.Child>

        <div className='fixed inset-0 overflow-y-auto'>
          <div className='flex min-h-full items-center justify-center p-4 text-center'>
            <Transition.Child
              as={Fragment}
              enter='ease-out duration-300'
              enterFrom='opacity-0 scale-95'
              enterTo='opacity-100 scale-100'
              leave='ease-in duration-200'
              leaveFrom='opacity-100 scale-100'
              leaveTo='opacity-0 scale-95'
            >
              <Dialog.Panel className='w-full max-w-lg transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all'>
                <Dialog.Title as='h3' className='text-lg font-medium leading-6 text-gray-900'>
                  Authorization
                </Dialog.Title>
                <div className='mt-2'>
                  <p className='text-sm text-black'>
                    To continue with the purchase you need to be logged in. <br />
                    If you do not have an account you can create one
                  </p>
                </div>

                <div className='px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6 mt-10'>
                  <button
                    type='button'
                    className='border-transparent bg-[#7a5cff] px-4 py-2 text-sm text-white hover:text-black
                    sm:w-auto sm:text-sm flex w-full justify-center rounded border hover:bg-subscriptionBg 
                    focus:outline-none focus:ring-2 focus:ring-turquoise focus:ring-offset-2 transform 
                    hover:-translate-y-1 transition ease-in-out delay-150'
                    onClick={onConfirm}
                  >
                    Take Me To The Login page
                  </button>
                  <button
                    type='button'
                    className='inline-flex w-full justify-center px-4 py-2 text-sm text-gray-700 hover:underline hover:underline-offset-1
                    sm:ml-3 sm:w-auto sm:text-sm'
                    onClick={onCloseModal}
                  >
                    Cancel
                  </button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
};

export default AlertcheckoutModal;
