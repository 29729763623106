import React from 'react';
import './styles.css';

const Loader = () => {
  return (
    <div>
      <div className='spinner'>
        <div className='double-bounce1'></div>
        <div className='double-bounce2'></div>
      </div>
    </div>
  );
};

export default Loader;
