import { gql } from "@apollo/client";
import UserFragment from "../fragments/User.fragment";

export const CREATE_STRIPE_SUBSCRIPTION_FOR_WEB = gql`
    mutation createStripeSubscriptionForWeb($input: CreateStripeSubscriptionForWebInput) {
        createStripeSubscriptionForWeb(input: $input) {
            ...User
        }
    }
    ${UserFragment.fragment}
`;

export default CREATE_STRIPE_SUBSCRIPTION_FOR_WEB;
