import { gql } from '@apollo/client';
import StripeSubscriptionFragment from './StripeSubscription.fragment';

const selection = `
  _id
  email
  firstName
  lastName
  fullName
  phone
  hasSubscription
  unsubscribeUserEOM
  affiliateId
  appUserId
	stripeData {
		id
		status
		customerId
		subscriptionId
		defaultPaymentMethodId
		paymentMethodIds
    refund {
      id
      refunded
      createdAt
    }
		createdAt
		updatedAt
	}
  revenueData {
    _id
    appUserId
    action
    price
    product_id
    status
    store
  }
  profileImage {
    _id
    title
    filename
    createdBy {
      _id
      email
    }
    imageTypes {
      size
      width
      height
    }
  }
  active
  quote`;

const UserFragment = {
  fragment: gql`
      fragment User on User{
          ${selection}
          stripe {...StripeSubscription}
          referredBy{
              _id
              firstName
              lastName
          }
          referredUser{
              _id
              firstName
              lastName
          }
      }
      ${StripeSubscriptionFragment.fragment}
  `,
};
export default UserFragment;
