import React from 'react';
import {Route} from 'react-router-dom';

const PublicRoute = (props: any) => {

  const {children, ...rest} = props;


  return (
    <Route
      {...rest}
      render={({location}) =>
        children
      }
    />
  )
};

export default PublicRoute;
