import { gql } from '@apollo/client';
import UserFragment from '../fragments/User.fragment';

export const CREATE_STRIPE_PAYMENT_INTENT = gql`
  mutation createStripePaymentIntent($input: CreateStripePaymentIntent!) {
    createStripePaymentIntent(input: $input)
  }
`;

export default CREATE_STRIPE_PAYMENT_INTENT;
