import {
    SHOPPING_CART_ADD_PRODUCT,
    SHOPPING_CART_ADD_QUICK_PURCHASE,
    SHOPPING_CART_EMPTY,
    SHOPPING_CART_REMOVE_PRODUCT,
    SHOPPING_CART_REMOVE_QUICK_PURCHASE,
    SHOPPING_CART_UPDATE_QUANTITY,
} from "../types/shoppingCart.types";
import { IProductState } from "../../shared/interfaces/redux/ProductState";
import { IStripeProduct } from "../../shared/interfaces/StripeProduct";
import { EProductType } from "../../shared/interfaces/Product";

export interface IShoppinCartState {
    products: IProductState[];
    quickPurchaseProduct: IProductState;
    error: string | null;
    loading: boolean;
    success: boolean;
}

const prod = localStorage.getItem(
    process.env.REACT_APP_LOCAL_STORAGE_SHOPPING_CART
);
const initialState: IShoppinCartState = {
    products: prod ? JSON.parse(prod).products : [],
    quickPurchaseProduct: null,
    error: null,
    loading: false,
    success: false,
};

const ShoppingCartReducer = (state = initialState, action: any) => {
    switch (action.type) {
        case SHOPPING_CART_ADD_PRODUCT:
            try {
                const pr = addProduct(
                    action.payload.product,
                    action.payload.quantity,
                    action.payload.productId,
                    action.payload.type
                );
                localStorage.setItem(
                    process.env.REACT_APP_LOCAL_STORAGE_SHOPPING_CART,
                    JSON.stringify({ products: pr })
                );
                return {
                    ...state,
                    quickPurchaseProduct: null,
                    error: null,
                    success: true,
                    products: pr,
                };
            } catch (e) {
                console.log(e);
                return {
                    ...state,
                    quickPurchaseProduct: null,
                    success: false,
                    error: "Error adding product",
                };
            }
        case SHOPPING_CART_REMOVE_PRODUCT:
            try {
                const products = deleteProduct(action.payload.productId);
                localStorage.setItem(
                    process.env.REACT_APP_LOCAL_STORAGE_SHOPPING_CART,
                    JSON.stringify({ products: products })
                );
                return {
                    ...state,
                    success: true,
                    quickPurchaseProduct: null,
                    error: false,
                    products,
                };
            } catch (e) {
                return {
                    ...state,
                    quickPurchaseProduct: null,
                    success: false,
                    error: "Error removing product",
                };
            }
        case SHOPPING_CART_UPDATE_QUANTITY:
            try {
                const tempProducts = updateQuantity(
                    action.payload.product,
                    action.payload.quantity,
                    action.payload.action,
                    state.products
                );
                localStorage.setItem(
                    process.env.REACT_APP_LOCAL_STORAGE_SHOPPING_CART,
                    JSON.stringify({ products: tempProducts })
                );
                return {
                    ...state,
                    products: tempProducts,
                    success: true,
                    error: false,
                    quickPurchaseProduct: null,
                };
            } catch (e) {
                return {
                    ...state,
                    quickPurchaseProduct: null,
                    success: false,
                    error: "Error updating product",
                };
            }
        case SHOPPING_CART_EMPTY:
            localStorage.removeItem(
                process.env.REACT_APP_LOCAL_STORAGE_SHOPPING_CART
            );
            return {
                products: [],
                quickPurchaseProduct: null,
                error: null,
                loading: false,
                success: false,
            };
        case SHOPPING_CART_ADD_QUICK_PURCHASE:
            try {
                const dp = {
                    product: action.payload.product,
                    quantity: action.payload.quantity,
                    productId: action.payload.productId,
                    type: action.payload.type,
                };
                localStorage.setItem(
                    process.env.REACT_APP_LOCAL_STORAGE_FAST_PURCHASE,
                    JSON.stringify(dp)
                );
                return {
                    ...state,
                    quickPurchaseProduct: dp,
                    success: true,
                    error: null,
                    loading: true,
                };
            } catch (e) {
                console.log(e);
                return {
                    ...state,
                    success: false,
                    error: "Error adding product",
                };
            }
        case SHOPPING_CART_REMOVE_QUICK_PURCHASE:
            try {
                localStorage.removeItem(
                    process.env.REACT_APP_LOCAL_STORAGE_FAST_PURCHASE
                );
                return {
                    ...state,
                    quickPurchaseProduct: null,
                    success: true,
                    error: null,
                    loading: true,
                };
            } catch (e) {
                return {
                    ...state,
                    success: false,
                    error: "Error adding product",
                };
            }
        default:
            return state;
    }
};

const addProduct = (
    product: IStripeProduct,
    quantity: number,
    productId: string,
    type: EProductType
) => {
    const productsStringified = localStorage.getItem(
        process.env.REACT_APP_LOCAL_STORAGE_SHOPPING_CART
    );
    let tempArray = [];

    if (productsStringified) {
        tempArray = JSON.parse(productsStringified).products;
    }

    const indexA = tempArray.findIndex(
        (prod) => prod.product.id === product.id
    );

    if (indexA >= 0) {
        tempArray[indexA].quantity += quantity;
    } else {
        tempArray.push({
            product,
            productId,
            quantity,
            type,
        });
    }
    return tempArray;
};

const deleteProduct = (productId): IProductState[] => {
    const productsStringified = localStorage.getItem(
        process.env.REACT_APP_LOCAL_STORAGE_SHOPPING_CART
    );
    if (productsStringified) {
        const productsArray = JSON.parse(productsStringified).products;
        return productsArray.filter(
            (product) => product.product.id !== productId
        );
    }
    return [];
};

const updateQuantity = (
    product: IStripeProduct,
    quantity: number,
    action: string,
    products: IProductState[]
): IProductState[] => {
    const productsStringi = localStorage.getItem(
        process.env.REACT_APP_LOCAL_STORAGE_SHOPPING_CART
    );
    if (productsStringi) {
        const productsArray = JSON.parse(productsStringi).products;
        const index = productsArray.findIndex(
            (prod) => prod.product.id === product.id
        );
        if (index < 0) {
            return productsArray;
        }
        let tempProducts = [...products];
        tempProducts[index].quantity =
            action === "ADD"
                ? tempProducts[index].quantity + quantity
                : tempProducts[index].quantity - quantity;

        if (tempProducts[index].quantity === 0) {
            tempProducts = tempProducts.filter(
                (prodState) => prodState.product.id !== product.id
            );
        }
        return tempProducts;
    }
};
export default ShoppingCartReducer;
