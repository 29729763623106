import { Alert } from '@material-ui/lab';
import React, { useState } from 'react';
import { IUser } from '../../../../shared/interfaces/User';
import MemberShip from './Components/MemberShip';
import PaymentInfo from './Components/PaymentInfo';
import { IonEvent } from './interface/onEvent.interface';

interface IProps {
  user?: IUser;
  subParams?: any;
  onEvent: (ev?: string) => void;
}

const MyAccount = (props: IProps) => {
  const { user, subParams, onEvent } = props;
  const [showMessage, setShowMessage] = useState<boolean>(false);
  const [message, setMessage] = useState<string>(null);
  const [severity, setSeverity] = useState<any>('success');

  const handleEvent = (ev: IonEvent) => {
    if (ev && ev?.data) {
      setSeverity(ev.data.severity);
      setMessage(ev.data.message);
      setShowMessage(true);
      onEvent('refresh');
    }
  };

  return (
    <>
      <div className='px-4 md:px-24 pt-4 pb-2 md:pt-10 flex flex-col space-y-6'>
        <div className='text-2xl md:text-3xl'>My Account</div>
        {showMessage && (
          <Alert severity={severity} onClose={() => setShowMessage(false)}>
            {message}
          </Alert>
        )}
        <div className='flex md:flex-row flex-col min-h-[650px] md:min-h-[550px] border rounded-lg'>
          <MemberShip user={user} onEvent={handleEvent} />
          <div
            className='md:w-3/5 pb-4 md:pb-0 px-4 md:px-10 space-y-10 bg-subscriptionBg md:rounded-r-lg md:rounded-b-none
           rounded-b-lg'
          >
            <PaymentInfo user={user} onEvent={handleEvent} />
          </div>
        </div>
      </div>
    </>
  );
};

export default MyAccount;
