import { gql } from '@apollo/client';

export const GET_STRIPE_USER_SUBSCRIPTION = gql`
  query getUserStripeSubscriptionInfo {
    getUserStripeSubscriptionInfo {
      subscription {
        id
        cancel_at
        cancel_at_period_end
        canceled_at
        currency
        created
        current_period_end
        current_period_start
        customer
        default_payment_method
        start
        start_date
        status
        plan {
          id
          object
          active
          amount
          amount_decimal
          billing_scheme
          created
          currency
          interval
          interval_count
          livemode
          product
          usage_type
        }
        discount
      }
      schedule {
        id
        customer
        start_date
        end_date
        end_behavior
        prorate
        prorate_behavior
        price {
          id
          unit_amount
          interval
        }
        product {
          id
          name
          interval
        }
      }
    }
  }
`;

export default GET_STRIPE_USER_SUBSCRIPTION;
