import {gql} from "@apollo/client";
import UserFragment from "../fragments/User.fragment";

const UPDATE_USER_PROFILE = gql`
    mutation UpdateUserProfile($input: UserUpdateProfile) {
        UpdateUserProfile(input:$input){
            ...User
        }
    }
    ${UserFragment.fragment}
`;

export default UPDATE_USER_PROFILE;
