import { Fragment, useRef, useState } from 'react';
import { Dialog, Transition } from '@headlessui/react';

import { CardElement, useElements, useStripe } from '@stripe/react-stripe-js';

import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useMutation } from '@apollo/client';
import CREATE_PAYMENT_METHOD from '../../../../../graphql/paymentMethod/createPaymentMethod.mutation';
import GET_PAYMENT_METHOD from '../../../../../graphql/paymentMethod/retrievePaymentMethod.query';
import RoundSpinner from '../Components/Spinner';
import { Alert } from '@material-ui/lab';
import { getApolloError } from '../../../../../shared/utils/apolloError';
import { PaymentMethodResult } from '@stripe/stripe-js';
import { IStripeProduct } from '../../../../../shared/interfaces/StripeProduct';
import SmallCard from '../../Subscription/Components/SmallCard';
import { Disclosure } from '@headlessui/react';
import { ChevronUpIcon } from '@heroicons/react/20/solid';
import { XMarkIcon } from '@heroicons/react/20/solid';

interface IForm {
  giftName?: '';
  giftEmail?: '';
  message?: '';
  city: string;
  postal_code: string;
  state: string;
  line1: string;
  line2: string;
  name: string;
  cc_complete: boolean;
}

interface GiftData {
  giftName: string;
  giftEmail: string;
  message: string;
}

export type UserGiftData = GiftData | null;

interface IProps {
  children?: JSX.Element;
  open: boolean;
  isGift?: boolean;
  selectedProduct?: IStripeProduct;
  savings?: number;

  onConfirm: (result?: PaymentMethodResult, giftData?: UserGiftData) => void;
  onCloseModal: () => void;
}

const GiftPaymentMethodModal = (props: IProps) => {
  const { selectedProduct, savings, open, isGift = false, onConfirm, onCloseModal } = props;

  const stripe = useStripe();
  const elements = useElements();

  const cancelButtonRef = useRef(null);
  const [stripeLoading, setStripeLoading] = useState<boolean>(false);
  const [stringError, setStringError] = useState<string>(null);
  const [showAlert, setShowAlert] = useState<any>({
    severity: 'success',
    message: '',
    showMessage: false,
  });

  const [createPaymentMethod, { data, error, loading: paymentMethodLoading }] = useMutation(CREATE_PAYMENT_METHOD);

  const loading = paymentMethodLoading || stripeLoading;

  //TODO:SELECT A PREVIOUS PAYMENT METHOD
  const SubmitForm = async (values: IForm) => {
    setStripeLoading(true);
    const result = await stripe.createPaymentMethod({
      type: 'card',
      card: elements.getElement(CardElement),
      billing_details: {
        name: values.name,
        address: {
          city: values.city,
          country: 'US',
          postal_code: values.postal_code,
          state: values.state,
          line1: values.line1,
          line2: values.line2,
        },
      },
    });

    const giftData = {
      giftName: values.giftName,
      giftEmail: values.giftEmail,
      message: values.message,
    };
    setStripeLoading(false);

    if (!result.error) {
      createPaymentMethod({ variables: { input: { paymentMethodId: result.paymentMethod.id } } })
        .then(() => {
          setStripeLoading(false);
          onConfirm(result, giftData);
        })
        .catch(() => {
          setStripeLoading(false);
          onCloseModal();
        });
    } else {
      setStringError('Error: Credit card verification');
    }
  };

  const GiftSchema = Yup.object().shape({
    giftName: Yup.string().required('Required'),
    giftEmail: Yup.string().email('Invalid email').required('Required'),
    city: Yup.string().required('Required'),
    postal_code: Yup.string().required('Required'),
    state: Yup.string().required('Required'),
    line1: Yup.string().required('Required'),
    name: Yup.string().required('Required'),
    cc_complete: Yup.boolean().required('Required'),
  });

  const formik = useFormik({
    initialValues: {
      giftName: '',
      giftEmail: '',
      message: '',
      city: '',
      postal_code: '',
      state: '',
      line1: '',
      line2: '',
      name: '',
      cc_complete: false,
    },
    onSubmit: (values: IForm) => SubmitForm(values),
    validateOnChange: true,
    validateOnMount: true,
    validationSchema: GiftSchema,
  });

  const cardOptions = {
    hidePostalCode: true,
    style: {
      base: {
        color: '#000',
        fontSize: '18px',
        '::placeholder': {
          color: '#32325d',
        },
      },
      invalid: {
        color: '#fa755a',
        iconColor: '#fa755a',
      },
    },
  };

  const formHasChanged = (value): boolean => {
    return !!formik.touched[value] && !!formik.errors[value];
  };

  const _handleCardChange = (event) => {
    formik.setFieldValue('cc_complete', event.complete);
  };

  const resetVars = () => {
    setShowAlert({
      severity: 'success',
      message: '',
      showMessage: false,
    });
  };

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as='div'
        className='relative z-10'
        initialFocus={cancelButtonRef}
        onClose={() => {
          console.log('close');
        }}
      >
        <Transition.Child
          as={Fragment}
          enter='ease-out duration-300'
          enterFrom='opacity-0'
          enterTo='opacity-100'
          leave='ease-in duration-200'
          leaveFrom='opacity-100'
          leaveTo='opacity-0'
        >
          <div className='fixed inset-0 bg-gray-900 bg-opacity-75 transition-opacity' />
        </Transition.Child>

        <div className='fixed inset-0 z-10 overflow-y-auto'>
          <div className='flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0'>
            <Transition.Child
              as={Fragment}
              enter='ease-out duration-300'
              enterFrom='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
              enterTo='opacity-100 translate-y-0 sm:scale-100'
              leave='ease-in duration-200'
              leaveFrom='opacity-100 translate-y-0 sm:scale-100'
              leaveTo='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
            >
              <Dialog.Panel
                className='relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl 
              transition-all sm:my-8 sm:w-full sm:max-w-3xl py-2'
              >
                <div className='bg-white px-4 py-5'>
                  <div className='sm:flex sm:items-start'>
                    <div className='mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left'>
                      <div className='pt-2'>
                        {stringError && <Alert severity='error'>{stringError}</Alert>}
                        {error && <Alert severity='error'>{getApolloError(error)}</Alert>}
                      </div>
                      <div className='flex flex-col md:flex-row gap-6'>
                        <div className='md:w-2/5 mt-2 text-left'>
                          <Dialog.Title as='h3' className='text-2xl font-semibold leading-6 text-gray-900'>
                            Gift this Membership
                          </Dialog.Title>
                          <div className='pt-10'>
                            <SmallCard
                              isGift={isGift}
                              product={selectedProduct}
                              savings={savings}
                              onSubscribe={() => console.log}
                            />
                          </div>
                        </div>

                        <div className='text-left mt-2'>
                          <form id='payment-form' onSubmit={formik.handleSubmit}>
                            <div>
                              <Dialog.Title as='h3' className='my-4 text-2xl font-semibold leading-6 text-gray-900'>
                                Gifting Info
                              </Dialog.Title>

                              <div className='text-left pt-2  space-y-2'>
                                <label htmlFor='giftName' className='text-sm'>
                                  NAME YOU ARE GIFTING TO
                                </label>
                                <input
                                  name='giftName'
                                  type='text'
                                  required
                                  onChange={formik.handleChange}
                                  placeholder='Name *'
                                  className='relative block w-full appearance-none rounded border-gray-300 px-3 py-3 text-gray-900 placeholder-gray-500 focus:z-10 focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm'
                                />
                                {formik.errors['giftName'] && (
                                  <span className='text-xs text-[#F44336]'>*{formik.errors['giftName']}</span>
                                )}
                              </div>
                              <div className='space-y-2 pt-4'>
                                <div className='text-left space-y-2'>
                                  <label htmlFor='giftEmail' className='text-sm'>
                                    EMAIL ADDRESS
                                  </label>
                                  <input
                                    name='giftEmail'
                                    type='email'
                                    required
                                    onChange={formik.handleChange}
                                    placeholder='Email *'
                                    className='relative block w-full appearance-none rounded border-gray-300 px-3 py-3 text-gray-900 placeholder-gray-500 focus:z-10 focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm'
                                  />
                                  {formik.errors['giftEmail'] && (
                                    <span className='text-xs text-[#F44336]'>*{formik.errors['giftEmail']}</span>
                                  )}
                                </div>
                              </div>

                              {/* <Disclosure>
                                {({ open }) => (
                                  <>
                                    <Disclosure.Button
                                      className='flex w-full justify-between py-2 text-left text-sm  text-black hover:underline 
                                    focus:outline-none focus-visible:ring focus-visible:ring-purple-500 focus-visible:ring-opacity-75'
                                    >
                                      <span>
                                        SHORT MESSAGE ABOUT YOUR GIFT <span className='text-xs'>(Optional)</span>
                                      </span>
                                      <ChevronUpIcon
                                        className={`${
                                          open ? 'hidden' : 'rotate-180 transform'
                                        } h-5 w-5 text-purple-500`}
                                      />
                                      <XMarkIcon
                                        className={`${
                                          open ? 'rotate-180 transform' : 'hidden'
                                        } h-5 w-5 text-purple-500`}
                                      />
                                    </Disclosure.Button>
                                    <Disclosure.Panel className='pt-2 pb-2 text-sm text-gray-500'>
                                      <div className='text-left pt-2 space-y-2'>
                                        <textarea
                                          name='message'
                                          rows={4}
                                          className='block p-2.5 w-full text-sm text-gray-900 rounded border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600'
                                          onChange={formik.handleChange}
                                          placeholder="You are the wind beneath my wings. Here's a gift for you..."
                                        ></textarea>
                                      </div>
                                    </Disclosure.Panel>
                                  </>
                                )}
                              </Disclosure> */}
                            </div>
                            <div className='mt-8'>
                              <Dialog.Title as='h3' className='my-4 text-2xl font-semibold leading-6 text-gray-900'>
                                Your Payment Info
                              </Dialog.Title>
                              <label className='text-sm'>CREDIT CARD INFO</label>
                              <CardElement
                                className='py-3 px-1 border rounded font-light'
                                options={cardOptions}
                                onChange={_handleCardChange}
                              />
                              <div className='text-left pt-2'>
                                <input
                                  name='name'
                                  type='text'
                                  required
                                  onChange={formik.handleChange}
                                  placeholder="Card holder's name *"
                                  className='relative block w-full appearance-none rounded border-gray-300 px-3 py-3 text-gray-900 placeholder-gray-500 focus:z-10 focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm'
                                />
                                {formik.errors['name'] && (
                                  <span className='text-xs text-[#F44336]'>*{formik.errors['name']}</span>
                                )}
                              </div>
                              <div className='space-y-2 pt-4'>
                                <div className='text-left'>
                                  <label htmlFor='line1' className='text-sm'>
                                    BILLING ADDRESS
                                  </label>
                                  <input
                                    name='line1'
                                    type='text'
                                    required
                                    onChange={formik.handleChange}
                                    placeholder='Street Address 1 *'
                                    className='relative block w-full appearance-none rounded border-gray-300 px-3 py-3 text-gray-900 placeholder-gray-500 focus:z-10 focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm'
                                  />
                                  {formik.errors['line1'] && (
                                    <span className='text-xs text-[#F44336]'>*{formik.errors['line1']}</span>
                                  )}
                                </div>
                                <div className='text-left'>
                                  <input
                                    name='line2'
                                    type='text'
                                    onChange={formik.handleChange}
                                    placeholder='Street Address 2'
                                    className='relative block w-full appearance-none rounded border-gray-300 px-3 py-3 text-gray-900 placeholder-gray-500 focus:z-10 focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm'
                                  />
                                </div>
                                <div className='flex flex-col xs:flex-row gap-2'>
                                  <div className='text-left'>
                                    <input
                                      name='city'
                                      type='text'
                                      required
                                      onChange={formik.handleChange}
                                      placeholder='City *'
                                      className='relative block w-full appearance-none rounded border-gray-300 px-3 py-3 text-gray-900 placeholder-gray-500 focus:z-10 focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm'
                                    />
                                    {formik.errors['city'] && (
                                      <span className='text-xs text-[#F44336]'>*{formik.errors['city']}</span>
                                    )}
                                  </div>
                                  <div className='text-left'>
                                    <input
                                      name='state'
                                      type='text'
                                      required
                                      onChange={formik.handleChange}
                                      placeholder='State *'
                                      className='relative block w-full appearance-none rounded border-gray-300 px-3 py-3 text-gray-900 placeholder-gray-500 focus:z-10 focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm'
                                    />
                                    {formik.errors['state'] && (
                                      <span className='text-xs text-[#F44336]'>*{formik.errors['state']}</span>
                                    )}
                                  </div>
                                  <div className='text-left md:max-w-[150px]'>
                                    <input
                                      name='postal_code'
                                      type='text'
                                      required
                                      onChange={formik.handleChange}
                                      placeholder='Zip *'
                                      className='relative block w-full appearance-none rounded border-gray-300 px-3 py-3 text-gray-900 placeholder-gray-500 focus:z-10 focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm'
                                    />
                                    {formik.errors['postal_code'] && (
                                      <span className='text-xs text-[#F44336]'>*{formik.errors['postal_code']}</span>
                                    )}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className='px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6'>
                  <button
                    form='payment-form'
                    type='submit'
                    className={`border-transparent ${
                      !formik.isValid ? 'bg-gray-400' : 'bg-[#7a5cff]'
                    }  px-4 py-2 text-sm text-white hover:text-black sm:ml-3 sm:w-auto sm:text-sm flex w-full 
                    justify-center rounded border hover:bg-subscriptionBg focus:outline-none focus:ring-2 focus:ring-turquoise 
                    focus:ring-offset-2 transform hover:-translate-y-1 transition ease-in-out delay-150`}
                    disabled={!formik.isValid || loading}
                  >
                    {loading ? (
                      <div className='flex flex-row gap-2 items-center'>
                        <RoundSpinner color={'text-white'} />
                        <div>Saving...</div>
                      </div>
                    ) : (
                      <>Gift this Purchase</>
                    )}
                  </button>
                  <button
                    type='button'
                    className='inline-flex w-full justify-center px-4 py-2 text-sm text-gray-700 hover:underline hover:underline-offset-1
                    sm:ml-3 sm:w-auto sm:text-sm'
                    disabled={loading}
                    onClick={() => {
                      resetVars();
                      onCloseModal();
                    }}
                    ref={cancelButtonRef}
                  >
                    Cancel
                  </button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export default GiftPaymentMethodModal;
