import React, { FC, useContext, useEffect, useState } from 'react';
import AuthContainerForm from '../../Auth/components/AuthContainerForm';
import { createStyles, Grid, LinearProgress, makeStyles, TextField, Theme } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { getApolloError } from '../../../shared/utils/apolloError';
import Title from '../../../shared/components/styled/Title';
import Subtitle from '../../../shared/components/styled/Subtitle';
import ErrorMessage from '../../../shared/components/styled/ErrorMessage';
import Button from '../../../shared/components/MuiButton';
import AuthFooterClicky from '../../../shared/components/styled/AuthFooterClicky';
import { useHistory, useLocation } from 'react-router-dom';
import { AuthContext } from '../../../shared/context/AuthContext';
import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import LOGIN_MUTATION from '../../../graphql/auth/login.mutation';
import GET_USER_PROFILE from '../../../graphql/user/getUserProfile.query';
import { get } from 'lodash';
import { ACTIONS } from '../../../shared/context/AuthContext/Constants';
import { EMAIL_REGEX } from '../../../shared/utils/emailRegex';
import { useFormik } from 'formik';
import { NEW_SUBSCRIPTION_FLOW_ROUTE, ROOT_ROUTES } from '../../../shared/constants/routes';
import styled from 'styled-components';
import REGISTER_MUTATION from '../../../graphql/auth/register.mutation';
import Login from '../../Auth/pages/Login';

const Container = styled.div`
  padding-top: 16px;
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
`;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      paddingTop: 16,
      alignContent: 'center',
      textAlign: 'center',
    },
    containerLogin: {
      marginLeft: 'auto',
      marginRight: 'auto',
    },
    containerBtns: {
      marginTop: 8,
    },
  }),
);

function useQueryReactRouterDom() {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
}

interface IProps {}

const LoginRegisterPage: FC<IProps> = () => {
  const classes = useStyles();
  const history = useHistory();
  const reactRouterQuery = useQueryReactRouterDom();
  const { dispatch } = useContext(AuthContext);
  const [registerTheUser, setRegisterTheUser] = useState<boolean>(false);
  const [login, { error: loginError, loading: loginLoading }] = useMutation(LOGIN_MUTATION);
  const [register, { loading: registrationLoading, error: registrationError }] = useMutation(REGISTER_MUTATION);
  const [getUser, { data: getUserData, error: getUserError, loading: getUserLoading }] = useLazyQuery(
    GET_USER_PROFILE,
    { fetchPolicy: 'network-only' },
  );

  const loading = loginLoading || getUserLoading || registrationLoading;
  const error = loginError || getUserError || registrationError;

  const onSubmit = (values: { email: string; password: string; firstName?: string; lastName?: string }) => {
    const variables: any = {
      email: values.email,
      password: values.password,
    };
    if (registerTheUser) {
      variables.firstName = values.firstName;
      variables.lastName = values.lastName;
      register({ variables }).then((res) => {
        if (!res.errors || res.errors.length === 0) {
          performLogin({ email: values.email, password: values.password });
        }
      });
    } else {
      performLogin(values);
    }
  };

  const performLogin = (values) => {
    login({ variables: values })
      .then(async (res) => {
        if (!res.errors) {
          const token = get(res, 'data.Login.jwtBearer');
          const refreshToken = get(res, 'data.Login.jwtRefresh');
          if (token && refreshToken) {
            getUser();
            dispatch({ type: ACTIONS.LOGIN, token, refreshToken });
          }
        }
      })
      .catch();
  };

  const validate = (values: any) => {
    const errors: any = {};
    if (!values.email) {
      errors.email = 'Required';
    } else if (!EMAIL_REGEX.test(values.email)) {
      errors.email = 'Invalid email';
    }
    if (!values.password) {
      errors.password = 'Required';
    }
    if (!registerTheUser) {
      return errors;
    } else {
      if (!values.firstName) {
        errors.firstName = 'First name Required';
      }
      if (!values.lastName) {
        errors.lastName = 'Last name Required';
      }
      if (!values.repeatPassword) {
        errors.repeatPassword = 'Required';
      } else if (values.password !== values.repeatPassword) {
        errors.password = "Passwords don't match";
        errors.repeatPassword = "Passwords don't match";
      }
    }
    return errors;
  };

  const formik = useFormik({
    initialValues: {
      email: '',
      password: '',
    },
    onSubmit,
    validate,
  });

  const formHasChanged = (value): boolean => {
    return !!formik.touched[value] && !!formik.errors[value];
  };

  useEffect(() => {
    if (getUserData) {
      const temp = get(getUserData, 'UserProfile', null);
      if (temp) {
        dispatch({ type: ACTIONS.SET_USER, user: temp });
        history.push(
          `/${ROOT_ROUTES.NEW_SUBSCRIBE_FLOW}/${NEW_SUBSCRIPTION_FLOW_ROUTE.SELECT}${
            reactRouterQuery.get('plan') ? `?plan=${reactRouterQuery.get('plan')}` : ''
          }`,
        );
      }
    }
  }, [getUserData]);

  useEffect(() => {
    localStorage.clear();
    dispatch({ type: ACTIONS.LOGOUT });
  }, [dispatch]);

  const dataForLogin = {
    title: 'Login or Create an Account',
    subtitle: 'Or log in if you have an existing account',
  };

  return <Login subParams={dataForLogin} />;
};

export default LoginRegisterPage;
