import React, { useContext, useEffect, useState } from 'react';
import { useLazyQuery } from '@apollo/client';
import { get } from 'lodash';
import { IUser } from '../../shared/interfaces/User';
import HeaderV2 from '../../shared/components/Header/Header';
import MyAccount from './pages/MyAccount/MyAccount';
import NewSubscription from './pages/Subscription/Subscription';
import GET_USER_PROFILE from '../../graphql/user/getUserProfile.query';
import { AuthContext } from '../../shared/context/AuthContext';
import { ACTIONS } from '../../shared/context/AuthContext/Constants';
import Loader from './pages/MyAccount/Components/Loader';
import { Route, Switch, useHistory, useLocation, useRouteMatch } from 'react-router-dom';
import { ROOT_ROUTES } from '../../shared/constants/routes';
import Plans from './pages/Subscription/Plan';

function useQueryReactRouterDom() {
  const { search } = useLocation();
  return React.useMemo(() => new URLSearchParams(search), [search]);
}

const AccountManagementRouter = () => {
  const { dispatch, state } = useContext(AuthContext);
  const reactRouterQuery = useQueryReactRouterDom();
  const history = useHistory();
  let { path } = useRouteMatch();

  const [userInfo, setUserInfo] = useState<IUser>(null);

  // const { data: userData, error: userError } = useQuery(GET_USER_PROFILE);
  const [getUser] = useLazyQuery(GET_USER_PROFILE, {
    errorPolicy: 'none',
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      setUserInfo(get(data, 'UserProfile', null));
      dispatch({ type: ACTIONS.SET_USER, user: data });
      dispatch({ type: ACTIONS.SET_LOADING, isLoading: false });
    },
    onError: (error) => {
      console.log(error);
    },
  });

  const handleEvent = (e: string) => {
    if (e === 'reload') {
      history.replace(`/${ROOT_ROUTES.ACCOUNT_MANAGEMENT}`);
      getUser().then();
    } else {
      getUser().then();
    }
  };

  useEffect(() => {
    getUser();
  }, []);

  const subscription = userInfo?.stripeData;
  return (
    <Switch>
      <Route exact path={path}>
        <>
          <HeaderV2 />
          {state.isLoading ? (
            <Loader />
          ) : userInfo &&
            (subscription?.subscriptionId || subscription?.status === 'CANCELED' || userInfo.appUserId) ? (
            <MyAccount user={userInfo} subParams={reactRouterQuery.get('plan')} onEvent={handleEvent} />
          ) : (
            <NewSubscription user={userInfo} subParams={reactRouterQuery.get('plan')} onEvent={handleEvent} />
          )}
        </>
      </Route>
      <Route path={`${path}/plans`}>
        <HeaderV2 />
        <Plans userInfo={userInfo}></Plans>
      </Route>
    </Switch>
  );
};

export default AccountManagementRouter;
