import {gql} from "@apollo/client";
import PaymentMethodFragment from "../fragments/PaymentMethod.fragment";

export const CREATE_PAYMENT_METHOD = gql`

mutation createPaymentMethod($input: CreatePaymentMethodInput){
    createPaymentMethod(input: $input){
            ...PaymentMethod
        }
    }
    ${PaymentMethodFragment.fragment}
`;

export default CREATE_PAYMENT_METHOD;
