const StaticCard = () => {
  return (
    <>
      <div className='font-bold text-2xl md:text-[2.1rem] md:leading-10'>
        Start Creating Your Life <span className='text-textBlue'> Today.</span>
      </div>
      <div className='text-sm font-light'>
        When you subscribe, you’ll gain access to our library of over 100+ Guided meditations, inspirational audio
        courses, and yoga series to help support you in being the creator of your life.
      </div>
    </>
  );
};

export default StaticCard;
