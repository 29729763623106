import styled from 'styled-components';

const AuthContainerForm = styled.div`
  background-color: #e0e0e0;
  -webkit-border-radius: 16px;
  -moz-border-radius: 16px;
  border-radius: 16px;
  padding: 14px;
  max-width: 100%;
  text-align: center;
`;

export default AuthContainerForm;
