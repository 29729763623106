import { ApolloError, useMutation } from '@apollo/client';
import { LinearProgress, TextField } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { useFormik } from 'formik';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import REQUEST_EMAIL_VERIFICATION from '../../../../graphql/auth/requestEmailVerification.mutation';
import Button from '../../../../shared/components/Button';
import AuthFooter from '../../../../shared/components/styled/AuthFooter';
import AuthFooterClicky from '../../../../shared/components/styled/AuthFooterClicky';
import Form from '../../../../shared/components/styled/Form';
import Subtitle from '../../../../shared/components/styled/Subtitle';
import Title from '../../../../shared/components/styled/Title';
import { getApolloError } from '../../../../shared/utils/apolloError';
import {EMAIL_REGEX} from "../../../../shared/utils/emailRegex";

interface IProps {
    header: JSX.Element;
    error?: string | ApolloError;
}

const ResendEmailVerificationForm = (props: IProps) => {
    const history = useHistory();
    const { error: errorFromParent } = props;
    const { header } = props;
    const [newVerificationSend, changeNewVerificationSend] = useState(false);
    const [requestEmailVerification, { data, loading, error: emailVerError }] = useMutation(REQUEST_EMAIL_VERIFICATION);
    const error = emailVerError || errorFromParent;

    const validate = (values: { email: string }) => {
        let errors: any = {};

        if (!values.email) {
            return errors.email = 'required';
        } else if (!EMAIL_REGEX.test(values.email)) {
            errors.email = 'Invalid email';
        }

        return errors;
    }

    const onSubmit = (values: { email: string }) => {
        requestEmailVerification({ variables: values });
    }

    const formik = useFormik({
        initialValues: {
            email: ''
        },
        onSubmit,
        validate
    });

    useEffect(() => {
        if (data) {
            changeNewVerificationSend(true);
        }
    }, [data]);

    const formHasChanged = (value): boolean => {
        return !!formik.touched[value] && !!formik.errors[value];
    };

    return (
        <div>
            {
                error &&
                <Alert severity="error">{getApolloError(error)}</Alert>
            }
            {
                newVerificationSend ?
                    <>
                        <Title>We sent you an email to verify your account.</Title>
                        <Subtitle>Please check your email</Subtitle>
                    </>
                    :
                    <>
                        {loading && <LinearProgress />}
                        {header}
                        <Form onSubmit={formik.handleSubmit}>
                            <TextField
                                className='input'
                                label={'Email'}
                                id={'email'}
                                name={'email'}
                                error={formHasChanged('email')}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                            />
                            <Button
                                style={{ marginTop: 16 }}
                                disabled={!formik.isValid || loading}>REQUEST EMAIL VERIFICATION</Button>
                        </Form>

                    </>
            }
            <Button
                style={{ marginTop: 16 }}
                onClick={() => history.push('/auth/login')}>BACK TO LOGIN</Button>
            <AuthFooter>If your problem persist, please contact system support to complete the verification process.</AuthFooter>
            <AuthFooterClicky>support@smallseed.com</AuthFooterClicky>
        </div>
    )
};

export default ResendEmailVerificationForm;
