import { useState, useEffect, useContext } from 'react';
import { useMutation, useQuery } from '@apollo/client';
import { get } from 'lodash';
import Swal from 'sweetalert2';

import GET_STRIPE_PRODUCT_SUBSCRIPTION from '../../../../graphql/stripe/getStripeProductSubscription.query';
import GET_PAYMENT_METHOD from '../../../../graphql/paymentMethod/retrievePaymentMethod.query';
import { IStripeProduct } from '../../../../shared/interfaces/StripeProduct';
import { IPaymentMethod } from '../../../../shared/interfaces/PaymentMethod';
import { IUser } from '../../../../shared/interfaces/User';
import { useHistory } from 'react-router-dom';
import { ROOT_ROUTES } from '../../../../shared/constants/routes';
import { AUTH_ROUTES } from '../../../../shared/constants/routes';
import CREATE_STRIPE_PAYMENT_INTENT from '../../../../graphql/stripe/createPaymentIntent.mutation';
import NewSubscription from '../../../AccountManagement/pages/Subscription/Subscription';
import { getApolloError } from '../../../../shared/utils/apolloError';
import RoundSpinner from '../../../AccountManagement/pages/MyAccount/Components/Spinner';
import { AuthContext } from '../../../../shared/context/AuthContext';
import Loader from '../../../AccountManagement/pages/MyAccount/Components/Loader';
import { ACTIONS } from '../../../../shared/context/AuthContext/Constants';

interface IProps {
  user?: IUser;
  subParams?: any;
  onEvent: (ev?: string) => void;
}

const NewCheckout = (props: IProps) => {
  const { user, subParams, onEvent } = props;
  const history = useHistory();
  const { dispatch, state } = useContext(AuthContext);

  const [showMessage, setShowMessage] = useState<boolean>(false);
  const [addPaymentMethod, setAddPaymentMethod] = useState<boolean>(false);
  const [showPayments, setShowPayments] = useState<boolean>(false);

  const [productPriceSelected, setProductPriceSelected] = useState<number>(null);
  const [selectedProduct, setSelectedProduct] = useState<IStripeProduct>(null);

  const [severity, setSeverity] = useState<any>('success');
  const [stripeProducts, setStripeProducts] = useState<IStripeProduct[]>([]);
  const [savings, setSavings] = useState<number>(0);

  const [giftEmail, setGiftEmail] = useState<string>(null);
  const [errors, setErrors] = useState<any>({
    email: null,
  });
  const [paymentMethods, setPaymentMethods] = useState<IPaymentMethod[]>([]);

  const { data: products, error: productError, loading: productLoading } = useQuery(GET_STRIPE_PRODUCT_SUBSCRIPTION);
  const { data: pmData } = useQuery(GET_PAYMENT_METHOD, { fetchPolicy: 'network-only' });

  const [createPaymentIntent, { loading: piLoading, error: piError }] = useMutation(CREATE_STRIPE_PAYMENT_INTENT, {
    fetchPolicy: 'no-cache',
  });

  const error = productError || piError;

  useEffect(() => {
    if (products) {
      let monthly = get(products, 'getStripeProductSubscription.monthlyGift', null);
      let annual = get(products, 'getStripeProductSubscription.annualGift', null);

      const individualAmount = (monthly.price.unit_amount_decimal * 12) / 100;
      const toSave = Math.round(100 - annual.price.unit_amount_decimal / individualAmount);

      monthly = { ...monthly, mode: 'month' };
      annual = { ...annual, mode: 'year' };
      const auxProducts = [monthly, annual];
      setStripeProducts(auxProducts);
      setSavings(toSave);
      if (subParams) {
        const filterQuery = subParams === 'annual' ? 'year' : 'month';
        const selected = auxProducts.filter((sp) => sp.mode === filterQuery);
        if (selected.length > 0) {
          onProductPriceSelected(selected[0]);
        }
      }
    }
  }, [products]);

  const toast = (severity: string, message: string) => {
    setSeverity(severity);
    setShowMessage(true);
    setTimeout(() => {
      setShowMessage(false);
    }, 2000);
  };

  useEffect(() => {
    dispatch({ type: ACTIONS.SET_LOADING, isLoading: false });
  }, []);

  useEffect(() => {
    if (error) {
      toast('error', error.message);
    }
  }, [error]);

  useEffect(() => {
    if (pmData) {
      setPaymentMethods(get(pmData, 'retrieveUserPaymentMethods.paymentMethods', []));
    }
  }, [pmData]);

  const onProductPriceSelected = (product: IStripeProduct) => {
    if (user) {
      setSelectedProduct(product);
      setProductPriceSelected(product.price.unit_amount);
      if (user?.stripeData?.paymentMethodIds?.length > 0) {
        setShowPayments(true);
      } else {
        setAddPaymentMethod(true);
      }
    }
  };

  const handleEvent = (e: string) => {
    if (e === 'success') {
      Swal.fire({
        title: 'Success!',
        text: 'Your gift subscription has been successfully created',
        icon: 'success',
        confirmButtonText: 'Continue',
      }).then((result) => {
        if (result.isConfirmed) {
          dispatch({ type: ACTIONS.SET_LOADING, isLoading: true });
          history.push(`/${ROOT_ROUTES.ACCOUNT_MANAGEMENT}`);
        }
      });
    } else {
      Swal.fire({
        title: 'Error!',
        text: 'Something went wrong. Contact support',
        icon: 'error',
        confirmButtonText: 'Close',
      });
    }
  };

  return <>{state.isLoading ? <Loader /> : <NewSubscription user={user} isGift={true} onEvent={handleEvent} />}</>;
};

export default NewCheckout;
