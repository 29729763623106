import { Fragment, useRef, useState } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { ExclamationTriangleIcon } from '@heroicons/react/24/outline';

export enum EModalType {
  CANCEL = 'cancel',
  UPDATE = 'update',
  REACTIVATE = 'reactivate',
}
interface IProps {
  children?: JSX.Element;
  title: string;
  body: string;
  cancelText: string;
  okText: string;
  open: boolean;
  type: EModalType;

  onConfirm: (type: EModalType) => void;
  onCloseModal: (type: string) => void;
}

const AlertModal = (props: IProps) => {
  const { title, body, cancelText, okText, open, type, onConfirm, onCloseModal } = props;

  const cancelButtonRef = useRef(null);

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as='div' className='relative z-10' initialFocus={cancelButtonRef} onClose={() => console.log('closing')}>
        <Transition.Child
          as={Fragment}
          enter='ease-out duration-300'
          enterFrom='opacity-0'
          enterTo='opacity-100'
          leave='ease-in duration-200'
          leaveFrom='opacity-100'
          leaveTo='opacity-0'
        >
          <div className='fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity' />
        </Transition.Child>

        <div className='fixed inset-0 z-10 overflow-y-auto'>
          <div className='flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0'>
            <Transition.Child
              as={Fragment}
              enter='ease-out duration-300'
              enterFrom='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
              enterTo='opacity-100 translate-y-0 sm:scale-100'
              leave='ease-in duration-200'
              leaveFrom='opacity-100 translate-y-0 sm:scale-100'
              leaveTo='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
            >
              <Dialog.Panel
                className='relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl 
              transition-all sm:my-8 sm:w-full sm:max-w-3xl py-6'
              >
                <div className='bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4'>
                  <div className='sm:flex sm:items-start'>
                    <div className='mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10'>
                      <ExclamationTriangleIcon className='h-6 w-6 text-red-600' aria-hidden='true' />
                    </div>
                    <div className='mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left'>
                      <Dialog.Title as='h3' className='text-xl font-medium leading-6 text-gray-900'>
                        {title}
                      </Dialog.Title>
                      <div className='mt-4'>
                        <p className='text-sm text-gray-500'>{body}</p>
                      </div>
                    </div>
                  </div>
                </div>

                <div className='px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6'>
                  <button
                    type='button'
                    className={`border-transparent ${
                      type === 'cancel'
                        ? 'bg-[#dd3d40] hover:bg-[#ad3638]'
                        : 'bg-[#7a5cff] hover:bg-subscriptionBg hover:text-black'
                    } px-4 py-2 text-sm text-white sm:ml-3 sm:w-auto sm:text-sm flex w-full 
                    justify-center rounded border focus:outline-none focus:ring-2 focus:ring-turquoise 
                    focus:ring-offset-2 transform hover:-translate-y-1 transition ease-in-out delay-150`}
                    onClick={() => {
                      onConfirm(type);
                    }}
                  >
                    {okText}
                  </button>
                  <button
                    type='button'
                    className='inline-flex w-full justify-center px-4 py-2 text-sm text-gray-700 hover:underline hover:underline-offset-1
                    sm:ml-3 sm:w-auto sm:text-sm'
                    onClick={() => {
                      onCloseModal(type);
                    }}
                    ref={cancelButtonRef}
                  >
                    {cancelText}
                  </button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export default AlertModal;
