import { gql } from '@apollo/client';
import StripeProductFragment from '../fragments/StripeProduct.fragment';

export const GET_PROMOTION_CODE = gql`
  query GetPromotionCode($input: PromoCodeInput!) {
    GetPromotionCode(input: $input)
  }
`;

export default GET_PROMOTION_CODE;
