import { useState, useEffect } from 'react';
import { Alert } from '@material-ui/lab';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe, PaymentMethodResult } from '@stripe/stripe-js';
import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import { get } from 'lodash';

import CREATE_STRIPE_SUBSCRIPTION_FOR_WEB from '../../../../graphql/stripe/createStripeSubscriptionForWeb.mutation';
import GET_STRIPE_PRODUCT_SUBSCRIPTION from '../../../../graphql/stripe/getStripeProductSubscription.query';
import GET_PAYMENT_METHOD from '../../../../graphql/paymentMethod/retrievePaymentMethod.query';
import { IStripeProduct } from '../../../../shared/interfaces/StripeProduct';
import { IPaymentMethod } from '../../../../shared/interfaces/PaymentMethod';
import RoundSpinner from '../MyAccount/Components/Spinner';
import PaymentMethodModal from '../MyAccount/Modals/PaymentMethod';
import Card from './Components/Card';
import StaticCard from './Components/StaticCard';
import { getApolloError } from '../../../../shared/utils/apolloError';
import { IUser } from '../../../../shared/interfaces/User';
import { Transition } from '@headlessui/react';
import PaymentModalSmall from '../MyAccount/Modals/PaymentModalSmall';
import { useHistory } from 'react-router-dom';
import AlertcheckoutModal from '../../../Checkout/pages/CheckoutNew/Modals/AlertCheckout';
import { AUTH_ROUTES, ROOT_ROUTES } from '../../../../shared/constants/routes';
import CREATE_STRIPE_PAYMENT_INTENT from '../../../../graphql/stripe/createPaymentIntent.mutation';
import GiftPaymentMethodModal, { UserGiftData } from '../MyAccount/Modals/GiftPaymentMethod';

interface IProps {
  user?: IUser;
  subParams?: any;
  isGift?: boolean;
  onEvent: (ev?: string) => void;
}

const NewSubscription = (props: IProps) => {
  const { user, subParams, isGift = false, onEvent } = props;
  const history = useHistory();
  const [isLogged, setIsLogged] = useState<boolean>(false);

  const [showMessage, setShowMessage] = useState<boolean>(false);
  const [addPaymentMethod, setAddPaymentMethod] = useState<boolean>(false);
  const [showPayments, setShowPayments] = useState<boolean>(false);

  const [productPriceSelected, setProductPriceSelected] = useState<string | number>(null);
  const [promoCode, setPromoCode] = useState<string>(null);
  const [selectedProduct, setSelectedProduct] = useState<IStripeProduct>(null);

  const [message, setMessage] = useState<string>(null);

  const [severity, setSeverity] = useState<any>('success');
  const [stripeProducts, setStripeProducts] = useState<IStripeProduct[]>([]);
  const [savings, setSavings] = useState<number>(0);

  const [paymentMethods, setPaymentMethods] = useState<IPaymentMethod[]>([]);
  const [showGiftPaymentModal, setShowGiftPaymentModal] = useState<boolean>(false);

  const { data: products, error: productError, loading: productLoading } = useQuery(GET_STRIPE_PRODUCT_SUBSCRIPTION);
  const { data: pmData } = useQuery(GET_PAYMENT_METHOD, { fetchPolicy: 'network-only' });

  const [createSubscription, { loading: newLoading, error: newError }] = useMutation(
    CREATE_STRIPE_SUBSCRIPTION_FOR_WEB,
    { fetchPolicy: 'no-cache' },
  );
  const [createPaymentIntent, { loading: piLoading, error: piError }] = useMutation(CREATE_STRIPE_PAYMENT_INTENT, {
    fetchPolicy: 'no-cache',
  });
  const error = newError || productError || piError;

  useEffect(() => {
    if (products) {
      let monthly = get(products, 'getStripeProductSubscription.monthly', null);
      let annual = get(products, 'getStripeProductSubscription.annual', null);
      const individualAmount = (monthly.price.unit_amount_decimal * 12) / 100;
      const toSave = Math.round(100 - annual.price.unit_amount_decimal / individualAmount);
      monthly = { ...monthly, mode: 'month' };
      annual = { ...annual, mode: 'year' };
      const auxProducts = [monthly, annual];
      setStripeProducts(auxProducts);
      setSavings(toSave);
      if (subParams) {
        const filterQuery = subParams === 'annual' ? 'year' : 'month';
        const selected = auxProducts.filter((sp) => sp.mode === filterQuery);
        if (selected.length > 0) {
          onProductPriceSelected(selected[0]);
        }
      }
    }
  }, [products]);

  const toast = (severity: string, message: string) => {
    setSeverity(severity);
    setMessage(message);
    setShowMessage(true);
    setTimeout(() => {
      setShowMessage(false);
    }, 2000);
  };

  useEffect(() => {
    if (error) {
      toast('error', error.message);
    }
  }, [error]);

  useEffect(() => {
    if (pmData) {
      setPaymentMethods(get(pmData, 'retrieveUserPaymentMethods.paymentMethods', []));
      // setDefaultSubPaymentMethodId(get(pmData, 'retrieveUserPaymentMethods.defaultPaymentSub', null));
    }
  }, [pmData]);

  const hideModals = () => {
    setShowGiftPaymentModal(false);
    setAddPaymentMethod(false);
    setShowPayments(false);
  };
  const onCreatePayment = (paymentMethodId: string, giftData: UserGiftData) => {
    hideModals();
    createPaymentIntent({
      variables: {
        input: {
          paymentMethodId,
          gifTo: giftData.giftEmail,
          giftName: giftData.giftName,
          message: giftData.message,
          interval: selectedProduct.mode,
          amount: selectedProduct.price.unit_amount,
          priceId: selectedProduct.price.id,
        },
      },
    })
      .then(() => {
        onEvent('success');
      })
      .catch(() => onEvent('error'));
  };

  const onCreateSubscription = (paymentMethodId: string, promoCode?: string) => {
    hideModals();
    createSubscription({
      variables: {
        input: {
          cvc: '',
          paymentMethodId,
          priceId: productPriceSelected,
          promoCode,
        },
      },
    }).then(() => {
      if (subParams) onEvent('reload');
      else onEvent('refresh');
    });
  };

  const onClose = () => setAddPaymentMethod(false);

  const handlePromotionCode = (e: any) => {
    setPromoCode(e.target.value);
  };

  const onProductPriceSelected = (product: IStripeProduct) => {
    if (!user && isGift) {
      setIsLogged(true);
      return;
    }
    setSelectedProduct(product);
    setProductPriceSelected(product.price.id);
    if (user?.stripeData?.paymentMethodIds?.length > 0) {
      setShowPayments(true);
    } else {
      setAddPaymentMethod(true);
    }
  };

  const onRedirect = () => history.push(`/${ROOT_ROUTES.AUTH}${AUTH_ROUTES.LOGIN}?returnTo=checkout`);

  const onHandleGift = (product: IStripeProduct) => {
    if (!user && isGift) {
      setIsLogged(true);
      return;
    }
    setSelectedProduct(product);
    setProductPriceSelected(product.price.unit_amount);
    setShowGiftPaymentModal(true);
  };
  return (
    <>
      <AlertcheckoutModal open={isLogged} onCloseModal={() => setIsLogged(false)} onConfirm={onRedirect} />
      <Elements stripe={loadStripe(process.env.REACT_APP_STRIPE)}>
        <PaymentMethodModal
          btnMessage='Purchase'
          showMembership={true}
          selectedProduct={selectedProduct}
          savings={savings}
          open={addPaymentMethod}
          onCloseModal={(pm, promotionCodeId) => onCreateSubscription(pm.paymentMethod.id, promotionCodeId)}
          onConfirm={onClose}
        />

        <GiftPaymentMethodModal
          open={showGiftPaymentModal}
          savings={savings}
          selectedProduct={selectedProduct}
          onConfirm={(pm, gift) => onCreatePayment(pm.paymentMethod.id, gift)}
          onCloseModal={() => setShowGiftPaymentModal(false)}
        />
      </Elements>
      <div className='px-4 md:px-24 py-12 md:pt-12 flex flex-col space-y-10'>
        <Transition
          show={showMessage}
          enter='transition-opacity duration-300'
          enterFrom='opacity-0'
          enterTo='opacity-100'
          leave='transition-opacity duration-300'
          leaveFrom='opacity-100'
          leaveTo='opacity-0'
        >
          {error ? (
            <Alert severity='error'>{getApolloError(error)}</Alert>
          ) : (
            <Alert severity={severity} onClose={() => setShowMessage(false)}>
              {message}
            </Alert>
          )}
        </Transition>
        <div className='flex flex-col md:flex-row gap-8 items-center'>
          <div
            className='flex relative flex-col gap-4 md:gap-10 rounded-lg border bg-cardBg text-black w-full md:max-w-[360px] md:h-[495px] 
        md:px-10 md:pr-12 md:pt-12 p-4'
          >
            <StaticCard />
          </div>

          <div className='space-y-6'>
            <Transition
              show={newLoading}
              enter='transition-opacity duration-300'
              enterFrom='opacity-0'
              enterTo='opacity-100'
              leave='transition-opacity duration-300'
              leaveFrom='opacity-100'
              leaveTo='opacity-0'
            >
              <div className='flex items-center'>
                <RoundSpinner color={'text-black'} /> Creating subscription...
              </div>
            </Transition>
            <h1 className='md:text-4xl'>Choose the Plan that works best for you.</h1>
            <div className='flex md:flex-row flex-col gap-8 justify-center items-center'>
              {productLoading ? (
                <>
                  {' '}
                  <RoundSpinner color={'text-black'} /> Loading plans...{' '}
                </>
              ) : (
                stripeProducts &&
                stripeProducts.map((product) => (
                  <div>
                    <Card
                      key={product.id}
                      product={product}
                      disableBtn={newLoading}
                      savings={savings}
                      isGift={isGift}
                      onGiftSubscription={onHandleGift}
                      onSubscribe={onProductPriceSelected}
                    />
                  </div>
                ))
              )}
            </div>
          </div>
        </div>
      </div>
      <PaymentModalSmall
        paymentMethods={paymentMethods}
        open={showPayments}
        onClose={() => setShowPayments(false)}
        onConfirm={onCreateSubscription}
      />
    </>
  );
};

export default NewSubscription;
