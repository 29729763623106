import { Fragment, useEffect, useRef, useState } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { IPaymentMethod } from '../../../../../shared/interfaces/PaymentMethod';
import { Elements } from '@stripe/react-stripe-js';
import PaymentMethodModal from './PaymentMethod';
import { loadStripe } from '@stripe/stripe-js';
import { useLazyQuery, useQuery } from '@apollo/client';
import GET_PAYMENT_METHOD from '../../../../../graphql/paymentMethod/retrievePaymentMethod.query';
import { get } from 'lodash';

interface IProps {
  open: boolean;
  paymentMethods: IPaymentMethod[];
  onConfirm: (payment: string) => void;
  onClose: () => void;
}

const PaymentModalSmall = (props: IProps) => {
  const { open, paymentMethods, onClose, onConfirm } = props;
  const cancelButtonRef = useRef(null);
  const [paymentId, setPaymentId] = useState<string>(null);
  const [error, setError] = useState<boolean>(false);
  const [addPaymentMethod, setAddPaymentMethod] = useState<boolean>(false);
  const [pmMethods, setPmMethods] = useState<IPaymentMethod[]>([]);

  const [getMethods, { data: pmData }] = useLazyQuery(GET_PAYMENT_METHOD, {
    errorPolicy: 'none',
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      setPmMethods(get(data, 'retrieveUserPaymentMethods.paymentMethods', []));
    },
    onError: (error) => {
      console.log(error);
    },
  });

  useEffect(() => {
    if (paymentMethods) setPmMethods(paymentMethods);
  }, [paymentMethods]);

  const onPaymentMethodSelected = (event: any) => {
    event.preventDefault();
    setError(false);
    setPaymentId(event.target.value);
  };

  useEffect(() => {
    if (pmData) {
      setPmMethods(get(pmData, 'retrieveUserPaymentMethods.paymentMethods', []));
    }
  }, [pmData]);

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as='div' className='relative z-10' initialFocus={cancelButtonRef} onClose={() => console.log('closing')}>
        <Transition.Child
          as={Fragment}
          enter='ease-out duration-300'
          enterFrom='opacity-0'
          enterTo='opacity-100'
          leave='ease-in duration-200'
          leaveFrom='opacity-100'
          leaveTo='opacity-0'
        >
          <div className='fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity' />
        </Transition.Child>

        <div className='fixed inset-0 z-10 overflow-y-auto'>
          <div className='flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0'>
            <Transition.Child
              as={Fragment}
              enter='ease-out duration-300'
              enterFrom='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
              enterTo='opacity-100 translate-y-0 sm:scale-100'
              leave='ease-in duration-200'
              leaveFrom='opacity-100 translate-y-0 sm:scale-100'
              leaveTo='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
            >
              <Dialog.Panel
                className='relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all 
              sm:my-8 sm:w-full sm:max-w-3xl py-6'
              >
                <div className='bg-white px-4 pt-5 pb-4 sm:p-2 sm:pb-4'>
                  <div className='flex sm:flex-none sm:items-start'>
                    <Elements stripe={loadStripe(process.env.REACT_APP_STRIPE)}>
                      <PaymentMethodModal
                        open={addPaymentMethod}
                        onCloseModal={() => setAddPaymentMethod(false)}
                        onConfirm={() => {
                          setAddPaymentMethod(false);
                          getMethods().then();
                        }}
                      />
                    </Elements>
                    <div className='mt-3 sm:mt-2 sm:ml-4 text-left sm:w-full'>
                      <Dialog.Title as='h3' className='text-xl font-medium leading-6 text-gray-900'>
                        Select Payment Method
                      </Dialog.Title>
                      <div className='mt-4'>
                        <div className='pt-6'>
                          <label htmlFor='paymentMethod' className='text-black text-sm font-light'>
                            PAYMENT METHOD
                          </label>
                          <select
                            id='paymentMethod'
                            className='bg-gray-50 border border-gray-300 text-black text-sm focus:ring-blue-500 
                            focus:border-blue-500 block p-3 font-light w-full'
                            onChange={onPaymentMethodSelected}
                            defaultValue='-1'
                          >
                            <option value='-1' selected>
                              --
                            </option>
                            {pmMethods &&
                              pmMethods.map((pm, ix) => (
                                <option key={pm.id} value={pm.id} className='text-xs' selected={ix === 0}>
                                  {`${pm.card.brand.toUpperCase()} ended in ${pm.card.last4} - Exp. ${
                                    pm.card.exp_month
                                  }/${pm.card.exp_year}`}
                                </option>
                              ))}
                          </select>
                          <div
                            className='mt-4 flex justify-center cursor-pointer'
                            onClick={() => setAddPaymentMethod(true)}
                          >
                            <p className='text-sm hover:underline hover:underline-offset-2'>+ Add New payment Method</p>
                          </div>
                        </div>
                        {error && <span className='text-xs text-[#F44336]'>*Select one payment method</span>}
                      </div>
                    </div>
                  </div>
                </div>

                <div className='px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6'>
                  <button
                    type='button'
                    className={`border-transparent  bg-[#7a5cff] hover:bg-subscriptionBg hover:text-black
                  px-4 py-2 text-sm text-white sm:ml-3 sm:w-auto sm:text-sm flex w-full 
                justify-center rounded border focus:outline-none focus:ring-2 focus:ring-turquoise 
                focus:ring-offset-2 transform hover:-translate-y-1 transition ease-in-out delay-150`}
                    onClick={() => {
                      if (paymentId === '-1') {
                        setError(true);
                        return;
                      }
                      onConfirm(paymentId);
                    }}
                  >
                    Continue
                  </button>
                  <button
                    type='button'
                    className='inline-flex w-full justify-center px-4 py-2 text-sm text-gray-700 hover:underline hover:underline-offset-1
                sm:ml-3 sm:w-auto sm:text-sm'
                    onClick={() => {
                      onClose();
                    }}
                    ref={cancelButtonRef}
                  >
                    Cancel
                  </button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export default PaymentModalSmall;
