import {gql} from '@apollo/client';

import PaymentMethodCardFragment from "./PaymentMethodCard.fragment";

const PaymentMethodFragment = {
  fragment: gql`
      fragment PaymentMethod on PaymentMethod{
          id
          type
          card { ...PaymentMethodCard }
      }
      ${PaymentMethodCardFragment.fragment}
  `

}
export default PaymentMethodFragment;
