import React from 'react';
import logo from '../../../../assets/images/infinity-white.png';
// import Video from '../../../../assets/video/co-create-background.mp4';
// import VideoGif from '../../../../assets/video/co-create-background.gif';
import useWindowSize from '../../../../shared/hooks/useWindowsSize';
import './styles.css';
const LayoutPage = ({ children }) => {
  const windowSize = useWindowSize();

  return (
    <div
      className='flex flex-col items-center justify-center text-center text-white py-0 px-3 h-full 
      min-h-screen bg-cover bg-no-repeat bg-fixed bg-center bg-[url("./App/assets/images/bg.png")]'
    >
      <div className='pt-8 overflow-hidden'>
        <img className='mx-auto w-[80px] md:w-[100px]' src={logo} alt='Co Create' />
        <h2 className='font-semibold md:text-2xl text-xl py-1'>Co Create</h2>
      </div>
      <div>{children}</div>
    </div>
  );
};

export default LayoutPage;
