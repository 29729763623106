import {gql} from "@apollo/client";

export const CANCEL_STRIPE_SUBSCRIPTION = gql`
    mutation cancelStripeSubscription{
        cancelStripeSubscription {
            message
        }
    }
`;

export default CANCEL_STRIPE_SUBSCRIPTION;
