import { gql } from '@apollo/client';

const VERIFY_WEB_AUTH =gql`
    mutation VerifyWebAuth($jwtWebAuth: String!) {
        VerifyWebAuth(input: {jwtWebAuth: $jwtWebAuth}) {
            email
            jwtBearer
            jwtRefresh
        }
    }
`;

export default VERIFY_WEB_AUTH;
