import {gql} from "@apollo/client";

export const REACTIVATE_STRIPE_SUBSCRIPTION = gql`
    mutation reactivateStripeSubscription{
        reactivateStripeSubscription {
            message
        }
    }
`;

export default REACTIVATE_STRIPE_SUBSCRIPTION;
